/**
 * Middleware to redirect to default region when none is provided
 */
import { useIpaperStore } from "~/stores/ipaper.store";

export default defineNuxtRouteMiddleware(async (to) => {
  const { getPath } = useRoutes();
  const { ipaperConfig } = useIpaperStore();

  // Initial Request to Nuxt
  if (to.params.region === "" && ipaperConfig?.defaultRegion) {
    return navigateTo(`${getPath("leaflets")}/${ipaperConfig.defaultRegion}`);
  }
});
